import React, { useEffect } from "react";
import s from "./TenderDetails.module.scss";
import Header from "../../components/Header/Header";
import {
  ClockCircleOutlined,
  FieldTimeOutlined,
  BlockOutlined,
  FormOutlined,
  FilePdfOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Divider, Flex, Tag } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTenderDetails } from "../../redux/ApplicationSlice";
var FileSaver = require("file-saver");

const TenderDetails = () => {
  const tender = useSelector((state) => state.application.tenderDetails);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const openFileInNewWindow = (e, link) => {
    e.preventDefault();
    const fileUrl = link;
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    dispatch(getTenderDetails(params.id));
  }, [dispatch, params.id]);

  const downloadFile = () => {
    var blob = new Blob([tender?.file], {
      type: "application/pdf;charset=utf-8",
    });
    FileSaver.saveAs(blob, "tender.pdf");
  };

  const { width } = useWindowDimensions();

  return (
    <div className={s.tenderDetails}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.divider}></div>
      <Link to={"/tenders"} className={s.back}>
        <ArrowLeftOutlined className={s.backIcon} /> &nbsp;&nbsp;Назад
      </Link>
      <div className={s.tender}>
        <div className={s.header}>
          <div className={s.number}>№ {tender?.tender_no}</div>
          <Tag
            color={
              tender?.status === "Актуальный"
                ? "#7eca8f"
                : tender?.status === "Завершен"
                ? "rgba(239, 91, 91, 0.927)"
                : "#82c9d5"
            }
            className={s.tag}
          >
            <CheckCircleOutlined /> &nbsp; {tender?.status}
          </Tag>
        </div>

        <div className={s.category}>
          {t("category")}: <strong>{tender?.category?.name}</strong>
        </div>
        <div className={s.title}>{tender?.title}</div>
        <div className={s.dates}>
          <div className={s.date}>
            <ClockCircleOutlined /> {t("published")}: {tender?.published_date}
          </div>
          <div className={s.deadline}>
            <FieldTimeOutlined /> {t("deadline")}:{" "}
            <strong> {tender?.deadline}</strong>
          </div>
        </div>
        <div
          className={s.tender_details}
          dangerouslySetInnerHTML={{ __html: tender?.description }}
        ></div>
        {tender?.file ? (
          <div className={s.files}>
            <button
              className={s.file}
              onClick={(e) => openFileInNewWindow(e, tender?.file)}
            >
              <FilePdfOutlined /> {tender?.file_name}
            </button>
            {/* <div className={s.btn} onClick={() => downloadFile()}>
              <DownloadOutlined />
            </div> */}
          </div>
        ) : null}
        {tender?.file_second ? (
          <div className={s.files}>
            <button
              className={s.file}
              onClick={(e) => openFileInNewWindow(e, tender?.file_second)}
            >
              <FilePdfOutlined /> {tender?.file_name_second}
            </button>
          </div>
        ) : null}
        {tender?.file_third ? (
          <div className={s.files}>
            <button
              className={s.file}
              onClick={(e) => openFileInNewWindow(e, tender?.file_third)}
            >
              <FilePdfOutlined /> {tender?.file_name_third}
            </button>
          </div>
        ) : null}
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default TenderDetails;
