import React from "react";
import s from "./Apprisers.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const Apprisers = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const apprisers = useSelector((state) => state.application.appraisers);
  const { t } = useTranslation();

  const downloadFile = async () => {
    const fileUrl = "/Критерии_отбора_независимых_оценщиков.pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "/Критерии_отбора_независимых_оценщиков.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };

  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 20,
      align: "center",
    },
    {
      title: t("independentAppraisers"),
      dataIndex: `appriser`,
      width: 400,
    },
    {
      title: t("contacts"),
      dataIndex: `contacts`,
      width: 600,
    },
  ];

  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <div className={s.documents_btns}>
          <div className={s.documents_item}>
            <button
              className={s.title}
              onClick={(e) =>
                openFileInNewWindow(
                  e,
                  "/Критерии_отбора_независимых_оценщиков.pdf"
                )
              }
            >
              <FilePdfOutlined className={s.icon} /> &nbsp;
              {t("CriteriaforSelectingIndependentAppraisers")}
            </button>
            <div className={s.btn} onClick={() => downloadFile()}>
              <DownloadOutlined className={s.icon} />
            </div>
          </div>

          <Link
            to='/vacancies/2'
            className={s.title}
            onClick={() => dispatch(getVacancyDetails(2))}
          >
            <FilePdfOutlined className={s.icon} /> &nbsp;{" "}
            {t("appraisersRecruitment")}
          </Link>
        </div>
        <h2 className={s.h2}>{t("accreditedAppraisers")}</h2>
        <Table
          bordered
          pagination={false}
          columns={columns}
          dataSource={apprisers?.map((it, index) => ({
            number: `${index + 1}.`,
            appriser: it?.description,
            contacts: (
              <div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>{t("phone")}: </div>
                  <div>{it?.phone}</div>{" "}
                </div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>{t("address")}: </div>
                  <div>{it?.address}</div>
                </div>
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Email: </div>{" "}
                  <a href={`mailto:${it?.email}`} className={s.site}>
                    {it?.email}
                  </a>
                </div>
                {it.website ? (
                  <div className={s.contactsItem}>
                    <div className={s.contactTitle}>{t("website")}: </div>
                    <Link to={`${it?.website}`} className={s.site}>
                      {it?.website}
                    </Link>
                  </div>
                ) : null}
              </div>
            ),
          }))}
          className='appriser_table'
        />
      </div>

      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Apprisers;
