import React from "react";
import Header from "../../components/Header/Header";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import s from "./InvestmentProductPassort.module.scss";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";

const InvestmentProductPassport = () => {
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const downloadFile = async () => {
    const fileUrl = "/Паспорт продукта инвестиционного финансирования.pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "/Паспорт продукта инвестиционного финансирования.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFileInNewWindow = (e) => {
    e.preventDefault();
    const fileUrl = "/Паспорт продукта инвестиционного финансирования.pdf";
    window.open(fileUrl, "_blank");
  };
  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <h2 className={s.h2}> {t("InvestmentFinancingProductPassport")}</h2>
        <div>
          <div className={s.documents_item}>
            <button className={s.title} onClick={(e) => openFileInNewWindow(e)}>
              <FilePdfOutlined className={s.icon} /> &nbsp;
              {"Паспорт продукта инвестиционного финансирования"}
            </button>
            <div className={s.btn} onClick={() => downloadFile()}>
              <DownloadOutlined />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default InvestmentProductPassport;
