import React from "react";
import s from "./InvestmentStrategy.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const InvestmentStrategy = () => {
  const { width } = useWindowDimensions();
  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <h2 className={s.title}>Инвестиционная стратегия</h2>
        <div className={s.text}>
          <div className={s.strong}>
            Инвестиционная стратегия предполагает формулирование общего уровня
            риск-аппетита на уровне Фонда и его каскадирование в конкретные
            ограничения для портфеля и отдельных инвестиций{" "}
          </div>
          <div className={s.strong} style={{ marginTop: "30px" }}>
            Риск-аппетит как видение в части управления рисками на уровне Фонда
          </div>
          Качественное формулирование основных принципов инвестирования и
          приемлемого уровня риска для Фонда
          <div className={s.strong}>Риск-аппетит на уровне портфеля</div>
          Каскадирование допустимого уровня риска на уровне портфеля (управление
          рисками концентрации)
          <div className={s.strong}>
            Риск-аппетит на уровне отдельной сделки
          </div>
          Каскадирование допустимого уровня риска на уровне отдельной сделки и
          операционного управления
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InvestmentStrategy;
