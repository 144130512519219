import React from "react";
import s from "./Consultants.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import ConsultantsMobileTable from "../../components/ConsultantsMobileTable/ConsultantsMobileTable";
import { useTranslation } from "react-i18next";

const Consultants = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const consultants = useSelector((state) => state.application.consultants);
  const { t } = useTranslation();

  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 20,
      align: "center",
    },
    {
      title: t("independentConsultants"),
      dataIndex: `consultant`,
      width: 400,
    },
    {
      title: t("contacts"),
      dataIndex: `contacts`,
      width: 600,
    },
    {
      title: t("industry"),
      dataIndex: `field`,
      width: 500,
    },
  ];

  const downloadFile = async () => {
    const fileUrl =
      "БАЗОВЫЕ_КРИТЕРИИ_АККРЕДИТАЦИИ_КОНСУЛЬТАНТА_И_СПИСОК_НЕОБХОДИМЫХ (7) (1).pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download =
      "БАЗОВЫЕ_КРИТЕРИИ_АККРЕДИТАЦИИ_КОНСУЛЬТАНТА_И_СПИСОК_НЕОБХОДИМЫХ (7) (1).pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };

  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.content}>
        <div className={s.documents_btns}>
          <div className={s.documents_item}>
            <Link
              className={s.title}
              onClick={(e) =>
                openFileInNewWindow(
                  e,
                  "БАЗОВЫЕ_КРИТЕРИИ_АККРЕДИТАЦИИ_КОНСУЛЬТАНТА_И_СПИСОК_НЕОБХОДИМЫХ (7) (1).pdf"
                )
              }
            >
              <FilePdfOutlined className={s.icon} /> &nbsp;
              {t("CriteriaforSelectingConsultants")}
            </Link>
            <div className={s.btn} onClick={() => downloadFile()}>
              <DownloadOutlined className={s.icon} />
            </div>
          </div>
          <Link
            to='/vacancies/1'
            className={s.title}
            onClick={() => dispatch(getVacancyDetails(1))}
          >
            <FilePdfOutlined className={s.icon} /> &nbsp;{" "}
            {t("consultantsRecruitmentByIndustry")}
          </Link>
          <Link
            to='/vacancies/3'
            className={s.title}
            onClick={() => dispatch(getVacancyDetails(3))}
          >
            <FilePdfOutlined className={s.icon} /> &nbsp;{" "}
            {t("BusinnesPlansConsultantsRecruitment")}
          </Link>
        </div>
        <h2 className={s.h2}>{t("AccreditedConsultantsForBusinessPlans")}</h2>
        {width > 800 ? (
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={consultants
              ?.filter((el) => el?.Industry?.includes("бизнес-планы и ТЭО"))
              ?.map((it, index) => ({
                number: `${index + 1}.`,
                consultant: it?.description,
                contacts: (
                  <div>
                    <div className={s.contactsItem}>
                      <div className={s.contactTitle}>{t("phone")}:</div>
                      <div>{it?.phone}</div>{" "}
                    </div>
                    {it?.email ? (
                      <div className={s.contactsItem}>
                        <div className={s.contactTitle}>Email:</div>
                        <a href={`mailto:${it?.email}`} className={s.site}>
                          {it?.email}
                        </a>
                      </div>
                    ) : null}

                    <div className={s.contactsItem}>
                      {it.website ? (
                        <div className={s.contactsItem}>
                          <div className={s.contactTitle}>{t("website")}:</div>
                          <Link to={`${it?.website}`} className={s.site}>
                            {it?.website}
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ),
                field: it?.Industry,
              }))}
            className='partner_table'
          />
        ) : (
          <ConsultantsMobileTable
            dataSource={consultants?.filter(
              (el) => el.Industry === "бизнес-планы и ТЭО"
            )}
          />
        )}
        <h2 className={s.h2}>{t("AccreditedConsultantsByIndustry")}</h2>
        {width > 800 ? (
          <Table
            bordered
            pagination={false}
            columns={columns}
            dataSource={consultants
              ?.filter((el) => el.Industry !== "бизнес-планы и ТЭО")
              ?.map((it, index) => ({
                number: `${index + 1}.`,
                consultant: it?.description,
                contacts: (
                  <div>
                    <div className={s.contactsItem}>
                      <div className={s.contactTitle}>{t("phone")}:</div>
                      <div>{it?.phone}</div>{" "}
                    </div>
                    {it?.email ? (
                      <div className={s.contactsItem}>
                        <div className={s.contactTitle}>Email:</div>
                        <a href={`mailto:${it?.email}`} className={s.site}>
                          {it?.email}
                        </a>
                      </div>
                    ) : null}

                    <div className={s.contactsItem}>
                      {it.website ? (
                        <div className={s.contactsItem}>
                          <div className={s.contactTitle}>{t("website")}:</div>
                          <Link to={`${it?.website}`} className={s.site}>
                            {it?.website}
                          </Link>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ),
                field: it?.Industry,
              }))}
            className='partner_table'
          />
        ) : (
          <ConsultantsMobileTable
            type='field'
            dataSource={consultants?.filter(
              (el) => el.Industry !== "бизнес-планы и ТЭО"
            )}
          />
        )}
      </div>

      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Consultants;
