import React, { useState } from "react";
import s from "./InvestmentPolicy.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Table } from "antd";
import { DownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";

const columns = [
  {
    title: "",
    dataIndex: "key",
    width: 50,
  },
  {
    title: "Цели",
    dataIndex: `goals`,
    width: 400,
  },
  {
    title: "Описание цели, критерии",
    dataIndex: `description`,
    width: 600,
  },
];

const dataSource = [
  {
    key: "1",
    goals: "Импортозамещение",
    description: (
      <div>
        Замещение импортируемых в Кыргызскую Республику товаров с помощью
        локализации производства внутри страны, как частично, так и полностью."
      </div>
    ),
  },
  {
    key: "2",
    goals: "Поддержка экспортоориентированных производств",
    description: (
      <div>
        Продвижение экспортной продукции с целью улучшения внешнеторговой
        позиции Кыргызской Республики.
      </div>
    ),
  },
  {
    key: "3",
    goals: "Кластеризация экономики",
    description: (
      <div>Создание и развитие устойчивых цепочек добавленной стоимости.</div>
    ),
  },
  {
    key: "4",
    goals: (
      <div>
        Создание и развитие производств с более высокой добавленной стоимостью
      </div>
    ),
    description: (
      <div>
        Повышение вклада в экономический рост и диверсификация экономики
        Кыргызской Республики.
      </div>
    ),
  },
  {
    key: "5",
    goals: <div>Инновационность проектов</div>,
    description: (
      <div>
        Организация технологии/производства в Кыргызской Республике с акцентом
        на инновационные и передовые решения.
      </div>
    ),
  },
  {
    key: "6",
    goals: (
      <div>
        Взаимодействие между Кыргызской Республикой и Азербайджанской
        Республикой
      </div>
    ),
    description: (
      <div>
        Эффективное использование возможностей двустороннего сотрудничества.
      </div>
    ),
  },
  {
    key: "7",
    goals: <div>Расширение источников инвестирования</div>,
    description: (
      <div>
        Финансирование инвестиционных проектов совместно с международными
        финансовыми институтами, финансово- кредитными организациями,
        иностранными инвесторами и другими заинтересованными сторонами.
      </div>
    ),
  },
  {
    key: "8",
    goals: <div>Развитие новых направлений</div>,
    description: (
      <div>
        Организация производства, использующего передовые технологии (в т.ч. в
        области «зеленого финансирования, инноваций и цифровых технологий)
      </div>
    ),
  },
];

const columns2 = [
  {
    title: "",
    dataIndex: "key",
    width: 300,
  },
  {
    title: "Показатель",
    dataIndex: `indicator`,
    width: 400,
  },
  {
    title: "Методология расчета",
    dataIndex: `metodology`,
    width: 600,
  },
  {
    title: "Пороговый уровень",
    dataIndex: `critical_level`,
    width: 100,
  },
];

const dataSource2 = [
  {
    key: (
      <strong style={{ lineHeight: 1.8 }}>Доходность на уровне проекта</strong>
    ),
    indicator: <div>Внутренняя норма доходности (IRR)</div>,
    metodology: (
      <div>
        Ставка дисконтирования, при которой NPV от всех денежных потоков в
        рамках проекта равен нулю (%)
      </div>
    ),
    critical_level: "≥8%",
  },
  {
    key: (
      <strong style={{ lineHeight: 1.8 }}>Доходность сделки для АКФР</strong>
    ),
    indicator: <div>Внутренняя норма доходности (IRR)</div>,
    metodology: (
      <div>
        Ставка дисконтирования, при которой NPV от всех денежных потоков для
        Компании равен нулю (%)
      </div>
    ),
    critical_level: "≥8%",
  },
  {
    key: "",
    indicator: <div>Мультипликатор капитала (MOIC)</div>,
    metodology: (
      <div>
        Суммарная стоимость всех полученных средств инвестора / вложенный
        капитал
      </div>
    ),
    critical_level: "≥1,1-2,5х",
  },
  {
    key: "",
    indicator: <div>Горизонт выхода из инвестиции</div>,
    metodology: (
      <div>
        Количество лет, через которое предполагается выход АКФР с достижением
        рассчитанного значения MOIC
      </div>
    ),
    critical_level: "до 7 лет",
  },
];

const columns3 = [
  {
    title: "№",
    dataIndex: "key",
    width: 50,
  },
  {
    title: "Стоп-факторы",
    dataIndex: `stop-factors`,
    width: 400,
  },
  {
    title: "Описание",
    dataIndex: `description`,
    width: 600,
  },
];

const dataSource3 = [
  {
    key: "1",
    goals: (
      <div>
        Принадлежность к определенной индустрии/сегменту Военно-промышленный
        комплекс включая производство изделий двойного назначения, военной
        формы, прочего обмундирования изделий из текстиля.
      </div>
    ),
    description: (
      <div>
        Военно-промышленный комплекс включая производство изделий двойного
        назначения, военной формы, прочего обмундирования изделий из текстиля. ​
        Принудительный и детский труд, игорный бизнес, казино и пр.
      </div>
    ),
  },
  {
    key: "2",
    goals: <div>Ранняя стадия инвестиционного проекта</div>,
    description: (
      <div>
        Проекты, по которым не разработан бизнес-план, финансовая модель и пр.
      </div>
    ),
  },
  {
    key: "3",
    goals: <div>Наличие репутационного риска</div>,
    description: (
      <div>Создание и развитие устойчивых цепочек добавленной стоимости.</div>
    ),
  },
];

const investmentPolicy = [
  {
    id: 1,
    title: "Инвестиционные принципы",
    text: (
      <div>
        <div className={s.text_item}>
          Настоящая Инвестиционная политика Азербайджано-Кыргызского Фонда
          развития (далее «Политика») разработана в соответствии с Уставом
          Азербайджано-Кыргызского Фонда развития (далее – «Фонд»/ «АКФР») в
          целях содействия экономическому сотрудничеству между Кыргызской
          Республикой̆ и Азербайджанской Республикой, модернизации и развитию
          экономик государств Сторон, эффективному использованию возможностей
          двустороннего сотрудничества.
        </div>
        <div className={s.text_item}>
          При осуществлении инвестиционной деятельности АКФР руководствуется
          рядом инвестиционных принципов для достижения следующих основных
          целей: осуществление прибыльных инвестиций в секторах экономики
          Кыргызской Республики совместно с инвесторами и финансовыми
          институтами; содействие в развитии инвестиционного потенциала
          Кыргызской Республики.
        </div>{" "}
        <div className={s.text_item}>
          Инвестиционные принципы направлены на обеспечение стабильного
          инвестиционного портфеля путем получения приемлемого уровня доходности
          инвестирования при ограниченном уровне риска."
        </div>
      </div>
    ),
  },
  {
    id: 2,
    title: "Соответствие инвестиционных проектов целям АКФР",
    text: (
      <div>
        <div className={s.text_item}>
          АКФР инвестирует в различные проекты, которые соответствуют одной или
          нескольким целям, заложенным в его деятельности. Детализация целей
          АКФР служит основой для более эффективного отбора проектов. Они
          выступают в роли нефинансовых критериев при проведении оценки
          инвестиционного проекта. Каждый потенциальный проект проходит проверку
          на соответствие целям АКФР.
        </div>
        <div className={s.text_item}>
          Определение количества целей, которым должен соответствовать проект,
          имеет важное значение для достижения целей АКФР и определяет весь
          диапазон потенциальных проектов. Для эффективного выбора проектов с
          наибольшим и сфокусированным воздействием на экономику страны
          используются нижеследующие цели:
        </div>
      </div>
    ),
  },
  {
    id: 3,
    title: "Приоритетные отрасли / сегменты",
    text: (
      <div className={s.text_item}>
        Наиболее привлекательными для рассмотрения считаются существующие
        секторы экономики, характеризующиеся стабильным и растущим спросом /
        потреблением, а также новые отрасли с высоким потенциалом роста. АКФР
        поддерживает инвестиционный портфель, сбалансированный по концентрации в
        различные секторы экономики. В качестве наиболее важных направлений
        выделяются:
        <div className={s.text_item}>
          ‑ агропромышленный комплекс; <br />
          ‑ сектор водоснабжения; <br />‑ сектор здравоохранения; <br />‑
          автомобильная промышленность; <br />‑ наука, исследования и
          технологии;
          <br />‑ газопромышленный комплекс; <br />‑ химическая и
          нефтехимическая промышленность; <br />‑ швейная и текстильная
          промышленность; <br />‑ обрабатывающая промышленность; <br />‑
          горнодобывающая и металлургическая промышленность; <br />‑ транспорт;{" "}
          <br />‑ жилищное строительство;
          <br /> ‑ торговля; <br />‑ развитие предпринимательства; <br />‑
          энергетический комплекс; <br />‑ развитие инфраструктуры; <br /> ‑
          информационные технологии; <br />‑ развитие туризма; <br />‑ другие
          отрасли экономики по решению Уполномоченного органа АКФР.
        </div>
        При этом АКФР не ограничивается инвестированием только в указанные
        отрасли. Он также может рассмотреть и инвестировать в проекты в других
        секторах экономики, при условии их соответствия целям и инвестиционным
        критериям АКФР."
      </div>
    ),
  },
  {
    id: 4,
    title: "Целевая география проектов",
    text: (
      <div>
        АКФР рассматривает возможность инвестирования в проекты, которые
        предполагают формирование производственных мощностей / активов на
        территории Кыргызской Республики.
      </div>
    ),
  },
  {
    id: 5,
    title: "Формат финансирования",
    text: (
      <div>
        <div className={s.text_item}>
          Оптимальный формат финансирования для АКФР предусматривает вхождение в
          капитал компании, предоставление долгового финансирования 1 в случае
          необходимости, в том числе с возможностью конвертации долга в долевое
          участие в капитале компании. Такой сбалансированный подход
          обеспечивает оптимальное сочетание уровня риска и доходности, что
          является предпочтительным для АКФР.
        </div>
        <div className={s.text_item}>
          При этом участие в капитале является приоритетным способом
          финансирования, а заемное финансирование используется в качестве
          дополнительного инструмента и применяется Компанией для хеджирования
          части доходности, только при условии обеспечения долга. Максимальное
          участие АКФР в проекте не более 49% от суммы проекта.
        </div>
      </div>
    ),
  },
  {
    id: 6,
    title: "Управление рисками",
    text: (
      <div>
        Управление рисками предполагает формулирование общего уровня
        риск-аппетита на уровне АКФР и последующее каскадирование этого уровня в
        конкретные ограничения для портфеля и отдельных инвестиций. Такой подход
        позволяет детализировать и применить этот уровень на уровне портфеля и
        отдельных инвестиционных проектов.
      </div>
    ),
  },
  {
    id: 7,
    title: "Требования к финансовой доходности",
    text: (
      <div>
        <div className={s.text_item}>
          При осуществлении процесса отбора проектов в рамках инвестиционной
          деятельности АКФР оценивает показатели доходности как самих
          инвестиционных проектов, так и доходности сделки для Фонда. Это
          включает анализ структуры финансирования проекта, объём
          инвестиционного финансирования проекта, а также иные условия
          инвестиционной сделки. Такой подход позволяет АКФР оценить
          потенциальные доходы и риски, связанные с каждой инвестицией, и
          сбалансировать портфель инвестиций для достижения оптимальной
          доходности и управления рисками.
        </div>
      </div>
    ),
  },
  {
    id: 8,
    title:
      "Степень участия в деятельности компаний, входящих в инвестиционный портфель АКФР",
    text: (
      <div>
        <div className={s.text_item}>
          АКФР активно участвует в принятии стратегических решений компаниями,
          входящими в его инвестиционный портфель, с помощью двух основных
          рычагов:
        </div>
        <div className={s.text_item}>
          <strong>
            1) Путем активного участия в органах управления компаний и тесного
            взаимодействия АКФР с их менеджментом:
          </strong>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            Представитель АКФР входит в состав совета директоров (наблюдательных
            советов) и других ключевых органах управления компаний, где
            обсуждаются и принимаются стратегически важные решения;
          </li>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            В случае возникновения риска невозврата вложенных инвестиций в
            период реализации проекта при необходимости АКФР осуществляет
            взаимодействие с компанией по операционным вопросам, поддерживает
            активную коммуникацию с компаниями, анализирует выполнение стратегии
            и операционные показатели, обсуждает возможные улучшения и
            оптимизации деятельности компаний, а также принимает участие в
            назначении ключевых руководителей (исполнительного органа,
            заместителей при наличии), включая финансового директора (CFO), для
            того, чтобы обеспечить качественное управление, эффективное
            финансовое планирование и контроль за ресурсами компании.
          </li>
        </div>
        <strong>
          <div className={s.text_item}>
            2) Путем установления ограничений и различных ковенант для компаний,
            включая право вето на ключевые решения:
          </div>
        </strong>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Целевое расходование привлеченных средств
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          {" "}
          Соблюдение законодательства
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Своевременность и полнота уплаты налогов
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Своевременная подготовка отчетности и пр.
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Найм генерального директора / топ-менеджмента компании
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Уровень компенсации для топ-менеджмента
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Смена аудитора
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          {" "}
          Реорганизация компании
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Слияния и поглощения
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Отчуждение активов компании
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Привлечение долгового финансирования
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Капитальные расходы
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Инвестирование средств
        </li>
        <li
          className={s.text_item}
          style={{ marginLeft: "25px", marginBottom: "5px" }}
        >
          Выплата дивидендов
        </li>
        <div className={s.text_item}>
          При необходимости АКФР может также вовлекаться в определенные аспекты
          операционной деятельности компаний, в том числе с помощью внешних
          экспертов, которые обладают специализированными знаниями и опытом в
          определенных областях бизнеса.
        </div>
      </div>
    ),
  },

  {
    id: 10,
    title: "Подход к отбору инвестиционных проектов",
    text: (
      <div>
        <div className={s.text_item}>
          Отбор проектов для инвестирования осуществляется поэтапно с
          использованием жестких и гибких критериев:
        </div>
        <strong>Этап 1: Жесткие критерии отбора</strong>
        <div>
          На этом этапе происходит первичный отсев проектов, и далее
          рассматриваются только те, которые соответствуют следующим жестким
          критериям:
        </div>
        <li className={s.text_item} style={{ marginLeft: "25px" }}>
          <strong>Отсутствие стоп-факторов:</strong> проекты, содержащие хотя бы
          один стоп- фактор, исключаются из дальнейшего рассмотрения, даже при
          наличии высоких финансовых и прочих показателей. Ключевыми
          стоп-факторами являются:
        </li>
        <li className={s.text_item} style={{ marginLeft: "25px" }}>
          <strong>Соответствие стратегическим целям АКФР.</strong> Проекты
          должны соответствовать целям деятельности АКФР, определенным Уставом
          АКФР.
        </li>
        <div className={s.text_item}>
          <strong>Этап 2: Гибкие критерии отбора</strong>
          <div className={s.text_item}>
            На этом этапе происходит комплексный анализ проекта и принимается
            окончательное решение об инвестировании на основе следующих гибких
            критериев:
          </div>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            <strong>Соответствие требованиям по финансовой доходности.</strong>{" "}
            Проект должен соответствовать критериям инвестиционной
            привлекательности, включая показатели доходности (IRR) и другие
            финансовые показатели (пункт 7 настоящей Политики);
          </li>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            <strong>
              Удовлетворительность прочих аспектов деятельности компании.
            </strong>
            Всесторонний анализ деятельности компании включает оценку её бизнес-
            модели, рынка, менеджмента, финансового состояния и других аспектов,
            которые могут влиять на успешность проекта и его соответствие целям
            АКФР.
          </li>
        </div>
      </div>
    ),
  },
  {
    id: 11,
    title: "Стратегия выхода из инвестиционных проектов",
    text: (
      <div>
        <div className={s.text_item}>
          Целевыми способами выхода из инвестиционных проектов для АКФР
          являются: продажа доли компании стратегическому партнеру или другому
          инвестору, который имеет 9 стратегический интерес в данной отрасли или
          бизнесе; иные способы выхода из инвестиционного проекта.
        </div>
        <div className={s.text_item}>
          Принятие решения о сроках выхода из инвестиционного проекта и
          требуемой доходности осуществляется на основе тщательного анализа и
          оценки различных факторов в момент одобрения проекта.
        </div>
        <div className={s.text_item}>
          При этом возможен досрочный выход АКФР из инвестиционного проекта при
          следующих случаях:
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            Неэффективность компании: если компания не демонстрирует ожидаемого
            уровня эффективности и не имеет потенциала для дальнейшего роста и
            развития;
          </li>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            Наличие заинтересованного покупателя, который готов приобрести долю
            в компании по цене, обеспечивающей приемлемую доходность (IRR) для
            АКФР.
          </li>
          <li className={s.text_item} style={{ marginLeft: "25px" }}>
            Прочие факторы.
          </li>
        </div>
        <div className={s.text_item}>
          Планирование способа выхода: Компания разрабатывает стратегию выхода
          еще до вхождения в капитал компании. Это в том числе включает в себя
          утверждение подхода к оценке компании в инвестиционном соглашении и
          определение ориентировочного периода владения. Кроме того, АКФР также
          учитывает возможность альтернативных способов выхода, таких как
          публичное размещение акций (IPO), слияния и поглощения и пр.
        </div>
      </div>
    ),
  },
];

const InvestmentPolicy = () => {
  const [chapter, setChapter] = useState(1);
  const [active, setActive] = useState(1);
  const { width } = useWindowDimensions();

  const downloadFile = async () => {
    const fileUrl = "/1.AKDF_Инвестиционная политика.pdf";
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "/1.AKDF_Инвестиционная политика.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openFileInNewWindow = (e, file) => {
    e.preventDefault();
    const fileUrl = file;
    window.open(fileUrl, "_blank");
  };
  return (
    <div>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.investmentPolicy}>
        <h2 className={s.title}>Инвестиционная политика</h2>
      </div>
      <div className={s.wrapper}>
        <div>
          {investmentPolicy.map((it) => (
            <li
              onClick={() => setChapter(it.id)}
              className={it.id === chapter ? s.active : s.chapter_title}
            >
              {it.title}
            </li>
          ))}
        </div>
        <div>
          <div className={s.chap_title}>
            {investmentPolicy.find((it) => it.id === chapter)?.title}
          </div>
          <div className={s.chap_text}>
            {investmentPolicy.find((it) => it.id === chapter)?.text}
            {chapter === 2 && (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            )}

            {chapter === 7 && (
              <Table
                dataSource={dataSource2}
                columns={columns2}
                pagination={false}
              />
            )}
          </div>
        </div>
      </div>
      <div className={s.documents_item}>
        <button
          className={s.btn_title}
          onClick={(e) =>
            openFileInNewWindow(e, "/1.AKDF_Инвестиционная политика.pdf")
          }
        >
          <FilePdfOutlined className={s.icon} /> &nbsp; ИНВЕСТИЦИОННАЯ ПОЛИТИКА
        </button>
        <div className={s.btn} onClick={() => downloadFile()}>
          <DownloadOutlined className={s.downloadIcon} />
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default InvestmentPolicy;
