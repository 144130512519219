import React, { useEffect, useState } from "react";
import s from "./AdminAccount.module.scss";
import Header from "../../components/Header/Header";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Popconfirm, Select, Table, Typography } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  getApplicationDetails,
  getApplicationsList,
  getMessages,
  getMyApplications,
  getUsersList,
  setMessageReceiver,
  updateStatusAndComment,
} from "../../redux/ApplicationSlice";
import UserApplication from "../UserApplication/UserApplication";
import AdminMobileTable from "../../components/AdminMobileTable/AdminMobileTable";
import UserMobileTable from "../../components/UserMobileTable/UserMobileTable";
import { EditOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
const { TextArea } = Input;

const AdminAccount = () => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isAppActive, setIsAppActive] = useState(true);
  const [isUsersActive, setIsUsersActive] = useState(false);
  const usersList = useSelector((state) => state.application.usersList);
  const applicationsList = useSelector(
    (state) => state.application.applicationsList
  );

  const [isOpen, setIsOpen] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState(applicationsList);
  const [editingId, setEditingId] = useState("");
  const isEditing = (record) => record.id === editingId;

  const edit = (record) => {
    const res = data.find((item) => item.id === record.id);

    form.setFieldsValue({
      status: res.status,
      admin_comments: res.admin_comments,
    });
    setEditingId(res.id);
  };

  const cancel = () => {
    setEditingId("");
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => id === item.id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setData(newData);
        setEditingId("");

        const res = await dispatch(
          updateStatusAndComment({
            id,
            status: row.status,
            admin_comments: row.admin_comments,
          })
        );

        if (res.type === "admin/patchStatusAndComment/fulfilled") {
          dispatch(getApplicationsList);
          dispatch(getMyApplications());
        }
      } else {
        newData.push(row);
        setData(newData);
        setEditingId("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    dispatch(getUsersList());
    dispatch(getApplicationsList());
  }, [dispatch]);

  const usersTableColumns = [
    {
      title: "№",
      dataIndex: `number`,
      width: 100,
    },
    {
      title: "ФИО",
      dataIndex: "full_name",
      width: 250,
    },
    {
      title: "Должность",
      dataIndex: "job_title",
      width: 250,
    },
    {
      title: "Компания",
      dataIndex: `company`,
      width: 300,
      align: "center",
    },
    {
      title: "Email",
      dataIndex: `email`,
      width: 125,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      width: 200,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      width: 200,
    },
  ];

  const columns = [
    {
      title: "№",
      dataIndex: `number`,
      width: 30,
    },
    {
      title: "Дата",
      dataIndex: `date`,
      width: 60,
    },
    {
      title: "ФИО",
      dataIndex: "full_name",
      width: 250,
    },
    {
      title: "Компания",
      dataIndex: "company",
      width: 200,
    },
    {
      title: "Сумма",
      dataIndex: "loan_amount",
      width: 100,
    },
    {
      title: "Заявка",
      dataIndex: `application`,
      width: 120,
      align: "center",
    },
    {
      title: "Статус",
      dataIndex: `status`,
      width: 125,
      editable: true,
    },
    {
      title: "Комментарий",
      dataIndex: "admin_comments",
      width: 200,
      editable: true,
    },
    {
      title: "",
      dataIndex: "operation",
      width: 40,

      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span
            onClick={() => save(record.id)}
            style={{ fontSize: "10px", color: "#d2b777" }}
          >
            <a>Сохранить</a>
            <br />
            <Popconfirm title='Отменить изменения?' onConfirm={cancel}>
              <a style={{ fontSize: "10px", color: "gray?", marginTop: "5px" }}>
                Отменить
              </a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            <EditOutlined className={s.icon} />
          </Typography.Link>
        );
      },
    },

    {
      title: "Сообщения",
      dataIndex: "chat",
      width: 50,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "admin_comments" ? "Textarea" : "Select",
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "Textarea" ? (
        <TextArea />
      ) : (
        <Select>
          <Option value='pending'>На рассмотрении</Option>
          <Option value='rejected'>Отклонено</Option>
          <Option value='approved'>Одобрено</Option>
        </Select>
      );
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <div>
      {width > 1088 ? <Header /> : <MobileHeader />}
      <div className={s.admin_account}>
        <div className={s.header}>
          <div className={s.h2}>
            {isUsersActive
              ? "Зарегистрированные пользователи"
              : "Предварительные заявления"}
          </div>
          <div className={s.btns}>
            <div
              className={isAppActive ? s.active_btn : s.btn}
              onClick={() => {
                setIsAppActive(true);
                setIsUsersActive(false);
              }}
            >
              Заявления
            </div>
            <div
              className={isUsersActive ? s.active_btn : s.btn}
              onClick={() => {
                setIsAppActive(false);
                setIsUsersActive(true);
              }}
            >
              Пользователи
            </div>
          </div>
        </div>
        {isUsersActive ? (
          <div>
            {width > 800 ? (
              <Table
                bordered
                pagination={false}
                columns={usersTableColumns}
                className={s.tenders_table}
                dataSource={usersList.map((it, index) => ({
                  number: index + 1,
                  full_name: it?.full_name,
                  job_title: it?.job_title,
                  company: it.company,
                  email: it?.email,
                  phone: it?.phone_number,
                  address: it?.address,
                }))}
              />
            ) : (
              <UserMobileTable
                users={usersList}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            )}
          </div>
        ) : (
          <div className={s.tableWrapper}>
            {width > 800 ? (
              <Form form={form} component={false} className={"admin_account"}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  rowClassName='editable-row'
                  bordered
                  rowKey={(record, index) => record.id || index}
                  pagination={false}
                  columns={mergedColumns}
                  className={s.tenders_table}
                  dataSource={data?.map((it, index) => ({
                    number: index + 1,
                    id: it.id,
                    date: moment(it?.created_at).format("DD.MM.YYYY"),
                    full_name: it.full_name,
                    company: it.company,
                    loan_amount: it?.requested_amount,
                    application: (
                      <div
                        className={s.more}
                        onClick={() => {
                          dispatch(getApplicationDetails(it?.id));
                          setIsOpen(true);
                        }}
                      >
                        Подробнее...
                      </div>
                    ),
                    intendedUse: it.intended_use,
                    status:
                      it?.status === "rejected"
                        ? "Отклонено"
                        : it?.status === "pending"
                        ? "На рассмотрении"
                        : "Одобрено",
                    admin_comments: it?.admin_comments,
                    chat: (
                      <Link
                        to={width > 700 ? `/chat/${it.id}` : `/chatSidebar`}
                        className={s.link}
                        onClick={() => {
                          dispatch(getMessages(it.id));
                          dispatch(
                            setMessageReceiver({
                              name: it.full_name,
                              email: it?.email,
                            })
                          );
                        }}
                      >
                        <img src='/assets/chat.png' className={s.chatIcon} />
                      </Link>
                    ),
                  }))}
                />
              </Form>
            ) : (
              <AdminMobileTable isOpen={isOpen} setIsOpen={setIsOpen} />
            )}
          </div>
        )}
      </div>
      <UserApplication isOpen={isOpen} setIsOpen={setIsOpen} user={false} />
    </div>
  );
};

export default AdminAccount;
