import React, { useState } from "react";
import Header from "../../components/Header/Header";
import s from "./Requirements.module.scss";
import { UploadOutlined } from "@ant-design/icons";
import Footer from "../../components/Footer/Footer";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";
import { Table } from "antd";

const Requirements = () => {
  const { height, width } = useWindowDimensions();
  const { t } = useTranslation();

  const downloadFile = (id) => {
    if (id === 1) {
      const fileUrl = "/Приложение №1 - Заявка на финансирование проекта.xlsx";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Приложение №1 - Заявка на финансирование проекта.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 2) {
      const fileUrl =
        "/Приложение №2 - Форма согласия о получении информации с КИБ.docx";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download =
        "/Приложение №2 - Форма согласия о получении информации с КИБ.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 3) {
      const fileUrl =
        "/Приложение №3 - Форма антикоррупционной декларации (1).docx";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download =
        "/Приложение №3 - Форма антикоррупционной декларации (1).docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (id === 4) {
      const fileUrl = "/Приложение №4- Форма бизнес-плана.docx";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "/Приложение №4- Форма бизнес-плана.docx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (id === 5) {
      const fileUrl =
        "/Приложение №1 - Заявка на финансирование проекта (Пример заполения для Сайта).xlsx";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download =
        "/Приложение №1 - Заявка на финансирование проекта (Пример заполения для Сайта).xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const openFileInNewWindow = (e, id) => {
    e.preventDefault();
    if (id === 1) {
      const fileUrl = "/Приложение №1 - Заявка на финансирование проекта.xlsx";
      window.open(fileUrl, "_blank");
    }
    if (id === 2) {
      const fileUrl =
        "/Приложение №2 - Форма согласия о получении информации с КИБ.docx";
      window.open(fileUrl, "_blank");
    }
    if (id === 3) {
      const fileUrl =
        "/Приложение №3 - Форма антикоррупционной декларации (1).docx";
      window.open(fileUrl, "_blank");
    }
    if (id === 4) {
      const fileUrl = "/Приложение №4- Форма бизнес-плана.docx";
      window.open(fileUrl, "_blank");
    }
    if (id === 5) {
      const fileUrl =
        "/Приложение №1 - Заявка на финансирование проекта (Пример заполения для Сайта).xlsx";
      window.open(fileUrl, "_blank");
    }
  };

  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 20,
      align: "center",
    },
    {
      title: "Наименование",
      dataIndex: `title`,
      width: 200,
    },
    {
      title: "Описание",
      dataIndex: `description`,
      width: 500,
    },
    {
      title: "Загрузка",
      dataIndex: `upload`,
      width: 30,
    },
  ];

  const columns2 = [
    {
      title: "Наименование",
      dataIndex: `title`,
      width: 200,
    },
    {
      title: "Описание",
      dataIndex: `description`,
      width: 500,
    },
  ];

  const data = [
    {
      number: "1",
      title: <div className={s.table_name}>Заявка на получение инвестиций</div>,
      description: (
        <div>
          <div className={s.description_item}>
            <div>Приложение №1.</div> &nbsp;
            <div className={s.link} onClick={(e) => openFileInNewWindow(e, 1)}>
              Заявка на финансирование проекта
            </div>
          </div>{" "}
          <div className={s.description_item}>
            <div> Пример заполнения Приложения №1.</div> &nbsp;
            <div className={s.link} onClick={(e) => openFileInNewWindow(e, 5)}>
              Заявка на финансирование проекта
            </div>
          </div>{" "}
          <div className={s.description_item}>
            Приложение №2. &nbsp;{" "}
            <div className={s.link} onClick={(e) => openFileInNewWindow(e, 2)}>
              Форма согласия о получении информации с КИБ
            </div>
          </div>{" "}
          <div className={s.description_item}>
            Приложение №3. &nbsp;{" "}
            <div className={s.link} onClick={(e) => openFileInNewWindow(e, 3)}>
              Форма антикоррупционной декларации
            </div>
          </div>
        </div>
      ),
      upload: (
        <div className={s.upload_wrapper}>
          <button className={s.upload_btn} onClick={() => downloadFile(1)}>
            <UploadOutlined />
          </button>
          <button className={s.upload_btn} onClick={() => downloadFile(5)}>
            <UploadOutlined />
          </button>
          <button className={s.upload_btn} onClick={() => downloadFile(2)}>
            <UploadOutlined />
          </button>
          <button className={s.upload_btn} onClick={() => downloadFile(3)}>
            <UploadOutlined />
          </button>
        </div>
      ),
    },
    {
      number: "2",
      title: (
        <div className={s.description_item}>
          Бизнес-план проекта (ТЭО) с финансовой моделью и структурой затрат
        </div>
      ),

      description: (
        <div>
          <div className={s.description_item}>
            Приложение №4.&nbsp;{" "}
            <div className={s.link} onClick={(e) => openFileInNewWindow(e, 4)}>
              Рекомендуемая форма.
            </div>
          </div>{" "}
          <div className={s.description_item}>
            Бизнес-план должен быть полный, с анализом рынка, конкурентной
            среды, себестоимости и описание технологического процесса.{" "}
          </div>
          <div className={s.description_item}>
            Финансовая модель в формате MS Excel. Структура затрат должна быть
            детальной со сметой на строительно-ремонтные работы, коммерческое
            предложение на приобретаемое оборудование и технику
          </div>
        </div>
      ),
      upload: (
        <div>
          <button className={s.upload_btn}>
            <UploadOutlined onClick={() => downloadFile(4)} />
          </button>
        </div>
      ),
    },
    {
      number: "3",
      title: (
        <div>
          Финансовые документы Компании/Поручителя/Группы связанных компаний
        </div>
      ),
      description: (
        <div>
          <div className={s.description_item}>
            Заверенные копии бухгалтерских балансов, отчетов о прибылях и
            убытках и отчета о движении денежных средств за последние 2 года и
            последний квартал текущего года.
          </div>{" "}
          <div className={s.description_item}>
            Финансовые документы за последний отчетный период предоставляется с
            расшифровками основных статей.{" "}
          </div>
          <div className={s.description_item}>
            Заверенные копии действующих контрактов, договоров и соглашений (а
            также действовавших за последние 2 года), касающиеся закупа
            сырья/материалов/услуг и реализации Компанией
            продукции/товаров/услуг.
          </div>
          <div className={s.description_item}>
            Банковские выписки по всем валютам и активным расчетным счетам за
            последние 2 года
          </div>
        </div>
      ),
    },
    {
      number: "4",
      title: (
        <div className={s.description_item}>
          Юридические документы (все документы должны быть прошнурованы и
          заверены печатью Заявителя)
        </div>
      ),
      description: (
        <div className={s.description_item}>
          Заверенные копии всех учредительных документов
          Компании/Поручителя/Группы связанных компаний. Копии паспортов,
          справки с МВД и резюме основного состава команды проекта.
        </div>
      ),
    },
    {
      number: "5",
      title: <div>Исходно-разрешительная документация</div>,
      description: (
        <div className={s.description_item_column}>
          <div>
            - Правоустанавливающие документы на место реализации проекта;
          </div>
          <div>- эскизный проект;</div>
          <div>- проект;</div>
          <div>- смета;</div>
          <div>
            - разрешение на строительство, выданное соответствующим
            государственным органом и пр.
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={s.reports_wrapper}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.reports}>
        <h2 className={s.h2}>{t("ListofRequiredDocuments")}</h2>
        <div className={s.subtitle}>
          Требуемые документы для рассмотрения заявки на инвестирование проекта:
        </div>
        <div className={s.table}>
          <Table
            columns={width > 550 ? columns : columns2}
            bordered
            dataSource={data}
            pagination={false}
          />
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Requirements;
