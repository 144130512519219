import React from "react";
import Header from "../../components/Header/Header";
import s from "./Vacancies.module.scss";
import VacanciesItem from "../../components/VacanciesItem/VacanciesItem";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVacancyDetails } from "../../redux/ApplicationSlice";
import useWindowDimensions from "../../components/WindowDimensions/WindowDimensions";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { useTranslation } from "react-i18next";

const Vacancies = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vacancies = useSelector((state) => state.application.vacancies);
  const { width } = useWindowDimensions();
  const filteredVacancies = vacancies?.filter(
    (it) => it.id !== 1 && it.id !== 2 && it.id !== 3
  );

  return (
    <div className={s.vacancies}>
      {width > 970 ? <Header /> : <MobileHeader />}
      <div className={s.wrapper}>
        {Array.isArray(filteredVacancies) && filteredVacancies.length > 0 ? (
          <h2 className={s.h2}>Вакансии</h2>
        ) : null}
        <div className={s.vacancy_list}>
          {Array.isArray(filteredVacancies) && filteredVacancies.length > 0 ? (
            filteredVacancies?.map((it) => (
              <Link
                to={`/vacancies/${it.id}`}
                onClick={() => dispatch(getVacancyDetails(it.id))}
              >
                <VacanciesItem data={it} />
              </Link>
            ))
          ) : (
            <div className={s.noVacancy}>{t("noAvailableVacancies")}</div>
          )}
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Vacancies;
