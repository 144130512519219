import React, { useEffect, useState } from "react";
import s from "./Application.module.scss";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Upload,
  Tooltip,
  Checkbox,
} from "antd";
import useWindowDimensions from "../WindowDimensions/WindowDimensions";
import {
  getApplicationsList,
  getMyApplications,
  sendApplication,
} from "../../redux/ApplicationSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  BankFilled,
  CalendarFilled,
  ContactsFilled,
  ExclamationCircleOutlined,
  FormOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Loading from "../Loading/Loading";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { TextArea } = Input;

const props = {
  name: "file",
  action: "", // No external URL needed
  customRequest({ file, onSuccess }) {
    // Simulate an upload delay (for progress bar effect)
    setTimeout(() => {
      // Store file in memory (or IndexedDB/localStorage)
      localStorage.setItem("uploadedFile", file.name);
      console.log("File stored temporarily:", file);

      onSuccess("ok"); // Mark upload as "done"
    }, 1000);
  },
};

const ApplicationDrawer = ({ open, onClose }) => {
  const { width } = useWindowDimensions();
  const [form] = Form.useForm();
  const loading = useSelector((state) => state.application.loading);
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const lang = useSelector((state) => state.application.lang);
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState([]);
  const user = useSelector((state) => state.application.user);
  const [projectTotalAmount, setProjectTotalAmount] = useState(0);
  const [ownContribution, setOwnContribution] = useState(0);

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Вы отправили информацию о проекте!",
    });
  };

  useEffect(() => {
    if (open === true) {
      setIsChecked(false);
    }
  }, [open]);

  const sendForm = async (values) => {
    console.log("values)", values);
    const data = new FormData();
    data.append("full_name", values.name);
    data.append("company", values.companyName);
    data.append("phone", values.phone);
    data.append("email", values.email);
    data.append("project_goal", values.projectGoal);
    data.append("project_description", values.projectDescription);
    data.append("industry.industry", values.projectField);
    data.append("total_cost", values.projectValue);
    data.append("intended_use", values.intendedUse);
    data.append("existing_business_description", values.businessDescription);
    data.append("requested_amount", values.requestedAmount);
    data.append("pledge", values.pledge);
    data.append("existing_loans", values.currentLoans);
    data.append("own_participation_amount", values.own_participation_amount);
    data.append("own_participation_form", values.own_participation_form);
    data.append(
      "requested_investment_period_akdf",
      values.requested_investment_period_akdf
    );
    data.append(
      "proposed_exit_procedure_akdf",
      values.proposed_exit_procedure_akdf
    );
    data.append(
      "other_project_initiator_information",
      values.other_project_initiator_information
    );

    if (file.fileList && file.fileList.length > 0) {
      file.fileList.forEach((fileItem) => {
        data.append("accompanying_documents", fileItem.originFileObj);
      });
    }

    const res = await dispatch(sendApplication(data));

    console.log("res", res);

    if (res.type === "application/sendApplication/fulfilled") {
      success();
      form.resetFields();
      setFile([]);

      if (!user?.is_admin) {
        dispatch(getMyApplications());
      } else {
        dispatch(dispatch(getApplicationsList()));
      }
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {contextHolder}
      <Drawer
        title={t("pre-application")}
        width={width > 1000 ? "90%" : "100%"}
        onClose={onClose}
        open={open}
      >
        <div className={s.intro}>
          <strong>{t("commitmentToCompleteApplication")}</strong> <br />
          {t("applicationIntro")}
          <div>
            {t("instructionsforFilling")}{" "}
            <InfoCircleOutlined className={s.infoIcon} />
          </div>
        </div>
        <Form
          form={form}
          layout='vertical'
          className={s.form}
          encType='multipart/form-data'
          onFinish={sendForm}
        >
          <div className={s.section}>
            <h3 className={s.h2}>
              <ContactsFilled className={s.icon} /> {t("aboutApplicant")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='companyName'
                  label={
                    <div>
                      {t("company")}{" "}
                      <Tooltip
                        placement='top'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleCompany")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterCompanyName"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterCompanyName")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='name'
                  label={
                    <div>
                      {t("name")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleName")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterFullName"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterFullName")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='phone'
                  label={
                    <div>
                      {t("contactPhone")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("examplePhone")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterPhoneNumber"),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterPhoneNumber")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='email'
                  label={
                    <div>
                      {t("email")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              Hydro@gmail.com
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    { required: true, message: t("enterEmail") },
                    { type: "email", message: t("enterValidEmail") },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterEmail")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <CalendarFilled className={s.icon} /> {t("aboutProject")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectGoal'
                  label={
                    <div>
                      {t("goal")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleGoal")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectGoal"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    placeholder={t("enterProjectGoal")}
                    style={{ height: "100px" }}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectDescription'
                  label={
                    <div>
                      {t("projectDescription")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("exampleDesc")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectDescription"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("enterProjectDescription")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectField'
                  label={
                    <div>
                      {t("projectIndustry")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("EnergySector")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("selectIndustry"),
                    },
                  ]}
                >
                  <Select
                    placeholder={t("selectIndustry")}
                    className={s.form_input}
                    style={{ padding: "0", borderRadius: "10px" }}
                  >
                    <Option value='агропромышленный комплекс'>
                      {t("agro")}
                    </Option>
                    <Option value='сектор водоснабжения'>{t("water")}</Option>
                    <Option value='сектор здравоохранения'>
                      {t("healthcare")}
                    </Option>
                    <Option value='автомобильная промышленность'>
                      {t("auto")}
                    </Option>
                    <Option value='наука, исследования и технология'>
                      {t("science")}
                    </Option>
                    <Option value='газопромышленный комплекс'>
                      {t("gas")}
                    </Option>
                    <Option value='химическая и нефтехимическая промышленность'>
                      {t("chemical")}
                    </Option>
                    <Option value='швейная и текстильная промышленность'>
                      {t("textile")}
                    </Option>
                    <Option value='обрабатывающая промышленность'>
                      {t("manufacturing")}
                    </Option>
                    <Option value='горнодобывающая и металлургическая промышленность'>
                      {t("mining")}
                    </Option>
                    <Option value='транспорт'>{t("transportation")}</Option>
                    <Option value='жилищное строительство'>
                      {t("residentialConstruction")}
                    </Option>
                    <Option value='торговля'>{t("trade")}</Option>
                    <Option value={t("entrepreneurshipDevelopment")}>
                      {t("entrepreneurshipDevelopment")}
                    </Option>
                    <Option value={t("energySector")}>
                      {t("energySector")}
                    </Option>
                    <Option value='развитие инфраструктуры'>
                      {t("infrastructureGrowth")}
                    </Option>
                    <Option value='информационные технологии'>{t("it")}</Option>
                    <Option value='развитие туризма'>{t("tourism")}</Option>
                    <Option value='прочие'>{t("other")}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='projectValue'
                  label={
                    <div>
                      {t("totalAmount")}
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>5 000 000 </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("enterProjectAmountandCurrency"),
                    },
                    {
                      validator: (_, value) =>
                        value && isNaN(Number(value))
                          ? Promise.reject(t("ThevalueMustBeNumeric"))
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterProjectAmountandCurrency")}
                    onChange={(e) => setProjectTotalAmount(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='own_participation_amount'
                  label={
                    <div>
                      {t("amountOfOwnParticipation")}, $&nbsp; (
                      {t("ofProjectAmount")})
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>2 550 000</div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    { required: true, message: t("amountOfOwnParticipation") },
                    {
                      validator: (_, value) => {
                        return new Promise((resolve, reject) => {
                          if (value && isNaN(Number(value))) {
                            return reject(t("ThevalueMustBeNumeric"));
                          }

                          if (Number(value) < 0.51 * projectTotalAmount) {
                            return reject(
                              `${t("amountOfOwnParticipation")} ${t(
                                "ofProjectAmount"
                              )}`
                            );
                          }

                          resolve();
                        });
                      },
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("amountOfOwnParticipation")}
                    onChange={(e) => setOwnContribution(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='own_participation_form'
                  label={
                    <div>
                      {t("FormOfOwnParticipation")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div style={{ marginBottom: "10px" }}>
                              {t("assets")}
                            </div>{" "}
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <div>
                                - {t("electricalEquipment")} – 1 000 000 $;
                              </div>
                              <div>
                                - {t("pressurePipeline")} – 1 200 000 $;;
                              </div>
                              <div>- {t("electricCable")} – 250 000 $;</div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[{ required: true, message: t("assets") }]}
                >
                  <TextArea
                    style={{
                      minHeight: "100px",
                      verticalAlign: "top",
                      height: "120px",
                      marginTop: "27px",
                    }}
                    className={s.form_input}
                    placeholder={t("FormOfOwnParticipation")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}></Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <BankFilled className={s.icon} />
              &nbsp;
              {t("aboutInvestments")}
            </h3>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='requestedAmount'
                  className={s.formItem}
                  label={
                    <div>
                      {t("requestedAmount")}, $ ({t("49%OfprojectCost")})
                      <Tooltip
                        placement='topLeft'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>2 450 000 </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("requestedAmount"),
                    },
                    {
                      validator: (_, value) => {
                        if (value && isNaN(Number(value))) {
                          return Promise.reject(t("ThevalueMustBeNumeric"));
                        }

                        if (value > 0.49 * projectTotalAmount) {
                          return Promise.reject(
                            t("TheNumberShouldNotExceed49%")
                          );
                        }

                        if (
                          Number(value) !==
                          Number(projectTotalAmount) - Number(ownContribution)
                        ) {
                          return Promise.reject(t("TotalProjectAmountMustBe"));
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    className={s.form_input}
                    placeholder={t("enterRequestedAmount")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='requested_investment_period_akdf'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("requestedInvestmentTerm"),
                    },
                  ]}
                  label={
                    <div>
                      {t("requestedInvestmentTerm")}
                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              5 {t("years")} <br />
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <Input
                    className={s.form_input}
                    style={{ marginTop: "28px" }}
                    placeholder={t("requestedInvestmentTerm")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='proposed_exit_procedure_akdf'
                  label={
                    <div>
                      {t("exitStrategy")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div style={{ marginBottom: "10px" }}>
                              {t("quarterlyOrAnnually")}
                            </div>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <div>{t("investmentPhase")}</div>
                              <div>{t("annualReturnInvestments")}</div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("exitStrategy"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px", marginTop: "28px" }}
                    placeholder={t("exitStrategy")}
                  />
                </Form.Item>
              </Col>

              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='intendedUse'
                  className={s.formItem}
                  label={
                    <div>
                      <div style={{ width: "450px" }}>{t("purpose")}</div>
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        arrow={false}
                        placement='topRight'
                        style={{
                          width: width > 600 ? "400px" : "320px",
                        }}
                        height={450}
                        width={320}
                        title={
                          <div
                            className={s.tooltip}
                            style={{
                              maxWidth: "400px",
                              width: width > 600 ? "400px" : "240px",
                            }}
                          >
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <table className={s.table}>
                                <tr>
                                  <th style={{ width: "20px" }}>№</th>
                                  <th
                                    style={{
                                      width: width > 600 ? "250px" : "125px",
                                    }}
                                  >
                                    {t("title")}
                                  </th>
                                  <th>{t("amount")}</th>
                                </tr>
                                <tbody>
                                  <tr>
                                    <td>1. </td>
                                    <td> {t("acquisitionofEquipment")} </td>
                                    <td>1 000 000$</td>
                                  </tr>
                                  <tr>
                                    <td>2.</td>
                                    <td>{t("acquisitionofPressure")}</td>
                                    <td>1 000 000$</td>
                                  </tr>
                                  <tr>
                                    <td>3.</td>
                                    <td> {t("constructionWork")} </td>
                                    <td>450 000 $</td>
                                  </tr>
                                  <tr>
                                    <td>4.</td>
                                    <td>…….</td>
                                    <td>…….</td>
                                  </tr>
                                  <tr>
                                    <td></td>
                                    <th>{t("total")}</th>
                                    <th>2 450 000 $</th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: t("specifyLoanPurpose"),
                    },
                  ]}
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "200px" }}
                    placeholder={t("specifyLoanPurpose")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className={s.section}>
            <h3 className={s.h2}>
              <FormOutlined className={s.icon} />
              &nbsp; {t("Other")}
            </h3>
            <Row gutter={16}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='businessDescription'
                  label={
                    <div>
                      {t("describeCurrentBusiness")}
                      <Tooltip
                        color={"rgb(249, 248, 248)"}
                        placement='topLeft'
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              {t("hydropowerPlant")}
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("describeCurrentBusiness"),
                    },
                  ]}
                >
                  <TextArea
                    style={{
                      height: "100px",
                    }}
                    className={s.form_input}
                    placeholder={t("describeCurrentBusiness")}
                  />
                </Form.Item>
              </Col>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='currentLoans'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("existingLoans"),
                    },
                  ]}
                  label={
                    <div>
                      {t("existingLoans")}

                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div children={s.tooltipTitle}>
                              {t("variant")} 1: &nbsp;
                            </div>
                            <div className={s.tooltipContent}>
                              {t("currentLoan")} <br />
                              <div>
                                <div
                                  className={s.tooltipTitle}
                                  style={{ marginTop: "5px" }}
                                >
                                  {t("variant")} 2: &nbsp;
                                </div>
                                <div className={s.tooltipContent}>
                                  {t("noLoans")}
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px", marginTop: "27px" }}
                    placeholder={t("enterExistingLoans")}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='other_project_initiator_information'
                  className={s.formItem}
                  rules={[
                    {
                      required: true,
                      message: t("additionalInformation"),
                    },
                  ]}
                  label={
                    <div>
                      {t("additionalInformation")}

                      <Tooltip
                        placement='topRight'
                        color={"rgb(249, 248, 248)"}
                        title={
                          <div className={s.tooltip}>
                            <div children={s.tooltipTitle}>
                              <ExclamationCircleOutlined
                                className={s.tooltip_text_icon}
                              />
                              {t("example")}
                            </div>
                            <div className={s.tooltipContent}>
                              <div> {t("licenses")}</div>
                              <div> {t("experience")}</div>
                              <div> {t("phasedFinancing")}</div>
                            </div>
                          </div>
                        }
                      >
                        <InfoCircleOutlined className={s.infoIcon} />
                      </Tooltip>
                    </div>
                  }
                >
                  <TextArea
                    className={s.form_input}
                    style={{ height: "100px" }}
                    placeholder={t("additionalInformation")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name='accompanying_documents'
                  label={t("uploadDocuments")}
                >
                  <Upload
                    name='accompanying_documents'
                    multiple
                    {...props}
                    onChange={(e) => setFile(e)}
                  >
                    <Button icon={<UploadOutlined />} className={s.btn}>
                      {t("upload")}
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={width > 800 ? 12 : 24}>
                <Form.Item
                  name={"checkbox"}
                  value={isChecked}
                  rules={[
                    {
                      required: isChecked ? false : true,
                      message: t("processingPersonalData"),
                    },
                  ]}
                >
                  <Checkbox
                    className={s.checkbox}
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  >
                    {t("processingPersonalData")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Space className={s.btns}>
            {width > 475 ? (
              <Button onClick={onClose} className={s.btn}>
                {t("cancel")}
              </Button>
            ) : null}
            <Button className={s.btn2} htmlType='submit'>
              {t("send")}
            </Button>
          </Space>
        </Form>
        <div className={s.loader}>
          {loading ? <Loading loading={loading} /> : null}
        </div>
      </Drawer>
    </div>
  );
};

export default ApplicationDrawer;
