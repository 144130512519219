import { Table } from "antd";
import React from "react";
import s from "./ConsultantMobileTable.module.scss";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";

const ConsultantsMobileTable = ({ dataSource }) => {
  const consultants = useSelector((state) => state.application.consultants);
  const columns = [
    {
      title: "№",
      dataIndex: "number",
      width: 10,
      align: "center",
    },
    {
      title: "Независимые консультанты",
      dataIndex: `consultant`,
    },
    {
      title: "Контакты",
      dataIndex: `contacts`,
    },
  ];

  return (
    <div className={s.table}>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={dataSource?.map((it, index) => ({
          number: `${index + 1}.`,
          consultant: it?.description,
          contacts: (
            <div>
              <div className={s.contactsItem}>
                <div className={s.contactTitle}>Тел:</div>
                <div>{it?.phone}</div>{" "}
              </div>
              {it?.email ? (
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Email:</div>
                  <a href={`mailto:${it?.email}`} className={s.site}>
                    {it?.email}
                  </a>
                </div>
              ) : null}
              {it?.website ? (
                <div className={s.contactsItem}>
                  <div className={s.contactTitle}>Cайт:</div>
                  <Link to={`${it?.website}`} className={s.site}>
                    {it?.website}
                  </Link>
                </div>
              ) : null}

              <div className={s.contactsItem}>
                <div className={s.contactTitle}>Отрасль:</div>
                <div>{it?.Industry}</div>
              </div>
            </div>
          ),
        }))}
        className='partner_mobile_table'
      />
    </div>
  );
};

export default ConsultantsMobileTable;
