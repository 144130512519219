import axios from "axios";

import { logout, refreshToken } from "./redux/ApplicationSlice.js";

const BASE_URL = "https://api.akfr.kg/";
// const BASE_URL = "http://138.68.94.26:8000/";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
});

const setupInterceptors = (store) => {
  userRequest.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.application.access;
      if (accessToken) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  userRequest.interceptors.response.use(
    (response) => response,

    async (error) => {
      const originalRequest = error.config;

      const isTokenError =
        error.response?.status === 401 &&
        error.response?.data?.code === "token_not_valid" &&
        error.response?.data?.messages?.[0]?.token_class === "AccessToken";

      if (isTokenError && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const result = await store.dispatch(refreshToken());
          if (result.payload?.access) {
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${result.payload.access}`;
            return userRequest(originalRequest);
          } else {
            store.dispatch(logout());
            return Promise.reject(error);
          }
        } catch (refreshError) {
          store.dispatch(logout());
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );
};

export { setupInterceptors };
