import React from "react";
import s from "./VacanciesItem.module.scss";
import moment from "moment";

const VacanciesItem = ({ data }) => {
  return (
    <div className={s.item}>
      <div className={s.title}>{data.title}</div>
      <div className={s.divider}></div>
      <div className={s.description}>{data.description}</div>
      <div className={s.date}>
        {moment(data.published_date).format("DD.MM.YYYY")}
      </div>
    </div>
  );
};

export default VacanciesItem;
